import { useState } from "react";

import Dialogue from "../components/Dialogue";

export default function Home() {
  const [dialogueResetFlag, setDialogueResetFlag] = useState(false);

  return (
    <>
      <Dialogue
        setResetFlag={setDialogueResetFlag}
        resetFlag={dialogueResetFlag}
      />
    </>
  );
}
