import { useEffect, useState, useRef } from "react";
import { List } from "antd";
import { Client } from "@stomp/stompjs";

import { ReactComponent as RedCircleIcon } from "../assets/images/redCircle.svg";
import { ReactComponent as GreenCircleIcon } from "../assets/images/greenCircle.svg";

import mockupSvc from "../services/mockup";

const scrollToBottom = (ref) =>
  (ref.current.scrollTop = ref.current.scrollHeight);

export default function Dialogue() {
  const [messages, setMessages] = useState([]);
  const [connected, setConnected] = useState(false);
  const [callStatus, setCallStatus] = useState(false);
  const [resetFlag, setResetFlag] = useState(false);
  const clientRef = useRef(null);
  const segmentRef = useRef();

  useEffect(() => {
    const client = new Client({
      brokerURL: `${process.env.REACT_APP_GATEWAY_ADV_WEBSOCKET_PROTOCOL}://${process.env.REACT_APP_GATEWAY_ADV_HOST}/advise`,
      onConnect: () => {
        console.log("Connected");
        setConnected(true);
        client.subscribe("/channel/call", (message) => {
          setCallStatus(true);
          setMessages((prevMessages) => [...prevMessages, message.body]);
        });

        client.subscribe("/channel/status", (message) => {
          message.body === "ON" ? setCallStatus(true) : setCallStatus(false);
        });
      },
      onDisconnect: () => {
        console.log("Disconnected");
        setConnected(false);
      },
      onStompError: (error) => {
        console.error("STOMP Error:", error);
      },
    });

    client.activate();
    clientRef.current = client;

    return () => {
      client.deactivate();
    };
  }, []);

  useEffect(() => {
    scrollToBottom(segmentRef);
  }, [messages]);

  useEffect(() => {
    if (resetFlag) {
      setMessages([]);
      setResetFlag(false);
    }
  }, [resetFlag]);

  // const sendMessage = () => {
  //   if (clientRef.current && clientRef.current.connected) {
  //     clientRef.current.publish({
  //       destination: "/app/message",
  //       body: JSON.stringify({ text: "test msg" }),
  //     });
  //   }
  // };

  return (
    <>
      <div className="flex p-2 mb-3">
        <button
          type="button"
          className="rounded-md bg-slate-600 px-3.5 py-2.5 mr-2 text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
          onClick={() => {
            setResetFlag(true);
            setCallStatus(true);
            mockupSvc.start().catch(() => {
              alert("서버 응답이 없습니다.");
            });
          }}
        >
          전송 시작
        </button>
        <button
          type="button"
          className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={() => {
            setResetFlag(true);
            mockupSvc.stop().catch(() => {
              alert("서버 응답이 없습니다.");
            });
          }}
        >
          전송 중지
        </button>

        {callStatus ? (
          <GreenCircleIcon className="ml-3 mt-0.5" />
        ) : (
          <RedCircleIcon className="ml-3 mt-0.5" />
        )}
      </div>

      <div
        className="langsa-scrollType-1 w-[450px] h-[70vh] overflow-y-auto"
        ref={segmentRef}
      >
        <List
          className="rounded-none"
          // header={<div>상담내역</div>}
          // footer={<div>Footer</div>}
          bordered
          dataSource={messages}
          renderItem={(item) => <List.Item>{item}</List.Item>}
        />
      </div>
    </>
  );
}
